<template>
  <div
    v-if="
      headerComponent &&
      headerComponent.component_variant &&
      headerComponent.component_variant.name === 'HEADER_1'
    "
  >
    <Theme2BaseHeaderOne
      :header-menu="headerMenu"
      :component="headerComponent"
    />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import { LayoutTypes } from '~/constants/ui.constants';
import { useWebsiteStore } from '~/units/website/store';
import type { ThemeDesignLayout } from '~/types';

export default defineNuxtComponent({
  name: 'Theme2BaseHeaderTheHeader',

  computed: {
    ...mapState(useWebsiteStore, ['layouts', 'headerMenuItems']),

    headerLayout(): ThemeDesignLayout {
      return this.layouts.find(
        (layout) => layout.layout.name === LayoutTypes.HEADER,
      );
    },

    headerComponent() {
      if (
        this.headerLayout &&
        this.headerLayout.components &&
        this.headerLayout.components[0]
      ) {
        return this.headerLayout.components[0];
      }
      return {};
    },

    headerMenu() {
      return this.headerMenuItems;
    },
  },
});
</script>
